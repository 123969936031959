import React, { useContext, useEffect, useState } from "react";
import ApplicationContext from "../../context/ApplicationContext";
import { IManagerProps } from "./IManagerProps";
import { Navigate, Route, Routes, useNavigate } from "react-router";
import { Epos } from "../pages/epos";
import { Login } from "../pages/login";

import "./styles/bootstrap.css";
import "./style.css";

export const Manager: React.FC<IManagerProps> = (
  props: IManagerProps
): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [vWidth, setWidth] = useState(window.innerWidth);
  const [vHeight, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const pages = (): JSX.Element => (
    <>
      <Routes>
        <Route path="/" element={<Navigate replace to="/Login" />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Menu/:loadData" element={<Epos />} />
        <Route path="*" element={<Navigate replace to="/Login" />} />
      </Routes>
    </>
  );

  return <>{pages()}</>;
};
