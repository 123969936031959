import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { IShowHideProps } from "./IShowHideProps";

export const ShowHideModal: React.FC<IShowHideProps> = (
  props: IShowHideProps
): JSX.Element => {
  const [appId, setAppId] = React.useState("");

  function showMenuBarAction(value: string) {
    let request = "https://menubar.WaEPOS.com/info?data=" + value;
    window.location.href = request;
    props.close();
  }

  const menuBarModal = (): JSX.Element => {
    return (
      <>
        <Modal show={props.show} onHide={() => props.close()} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Menu Bar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>This will show or hide menu bar</div>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button
              variant="success"
              style={{
                width: 150,
                height: 50,
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              onClick={() => showMenuBarAction("false")}
            >
              Hide Menu Bar
            </Button>
            <Button
              variant="success"
              style={{
                width: 150,
                height: 50,
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              onClick={() => showMenuBarAction("true")}
            >
              Show Menu Bar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  return (
    <>
      <>{menuBarModal()}</>
    </>
  );
};
