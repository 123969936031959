import React, { useState } from "react";
import { IManagerProps, Manager } from "./components/manager";
import ApplicationContext, { defaults } from "./context/ApplicationContext";
import { BrowserRouter } from "react-router";

function App() {
  const props: IManagerProps = {
    value: "",
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ApplicationContext.Provider value={defaults}>
        <BrowserRouter>
          <Manager {...props} />
        </BrowserRouter>
      </ApplicationContext.Provider>
    </div>
  );
}

export default App;
