import { IMenu } from "../../types/IMenu";
import { IBranchMenu } from "./IBranchMenu";

let menu: IMenu;

export class BranchMenu implements IBranchMenu {
  public setMenu(m: IMenu): void {
    menu = m;
  }
  public getMenu(): IMenu {
    return menu;
  }
}
