import React, { useContext, useEffect } from "react";
import ApplicationContext from "../../../context/ApplicationContext";
import { Button, Form, Modal } from "react-bootstrap";
import { ITotalProps } from "./ITotalProps";
import { IReceipt } from "../../../types/IReceipt";
import { createOrder } from "../../../services/Service";
import { IReceiptData } from "../../../types/IReceiptData";
import loadingImage from "../../../images/loading.gif";
import "./style.css";

export const TotalModal: React.FC<ITotalProps> = (
  props: ITotalProps
): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [toPayAmount, setToPayAmount] = React.useState("");
  const [payValue, setPayValue] = React.useState("");
  const [toPayType, setToPayType] = React.useState("Amount Due");
  const [payRemaining, setPayRemaining] = React.useState("");

  const [saving, setSaving] = React.useState(false);
  const [error, setError] = React.useState(false);

  const buttonStyle = {
    width: "15vh",
    height: "10vh",
    fontSize: "4vh",
    fontWeight: "bolder",
    maxWidth: "100px",
    maxHeight: "100px",
    padding: 0,
  };

  function appendChar(value: string) {
    let v = payValue;
    v = v + value;
    setPayValue(v);
  }

  function deleteLastChar() {
    const v = payValue;
    const newValue = v.substring(0, v.length - 1);
    setPayValue(newValue);
  }

  function updatePricing() {
    setToPayType("Amount Due");

    const enteredValue = payValue;
    const value = parseFloat(enteredValue);
    const total = parseFloat(toPayAmount);

    if (isNaN(value)) {
      setToPayType("Amount Due");
      setPayRemaining(getTotal().toFixed(2));
      return;
    }

    if (value < total) {
      const remainingValue = total - value;
      setPayRemaining(remainingValue.toFixed(2));
      return;
    }

    setToPayType("Change");
    const change = total - value;
    setPayRemaining((change * -1).toFixed(2));
  }

  useEffect(() => {
    updatePricing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payValue, props.toPayAmount]);

  useEffect(() => {
    updatePricing();
    setToPayAmount(props.toPayAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payValue, props.toPayAmount]);

  const savingData = (): JSX.Element => {
    return (
      <>
        {error ? (
          <div className="loading-center">
            {/* <img src={loadingImage} alt="Loading" /> */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Error in saving or printing. Please contact support on 07929-929070
          </div>
        ) : (
          <div className="loading-center">
            <img src={loadingImage} alt="Loading" />
          </div>
        )}
      </>
    );
  };

  const changeInGreen = () => (
    <>
      <div style={{ color: "green" }}>£{payRemaining}</div>
    </>
  );

  const dueInRed = () => (
    <>
      <div style={{ color: "red" }}>£{payRemaining}</div>
    </>
  );

  const paymentScreen = () => (
    <>
      <>
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div>
            <table>
              <tr>
                <td
                  style={{
                    width: 150,
                    backgroundColor: "whitesmoke",
                    verticalAlign: "top",
                    padding: 10,
                  }}
                >
                  {toPayType}:
                  <h1>
                    {toPayType === "Change" ? changeInGreen() : dueInRed()}
                  </h1>
                </td>
                <td style={{ width: 5, backgroundColor: "white" }}></td>
                <td>
                  <table>
                    <tr>
                      <td colSpan={4} style={{ backgroundColor: "" }}>
                        <Form.Control
                          placeholder="£"
                          aria-label="£"
                          aria-describedby="basic-addon1"
                          style={{
                            fontSize: "25px",
                            width: "40vw",
                            maxWidth: "400px",
                          }}
                          value={"£" + payValue}
                          onChange={() => updatePricing()}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => appendChar("7")}
                        >
                          7
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => appendChar("8")}
                        >
                          8
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => appendChar("9")}
                        >
                          9
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => setPayValue("5.00")}
                        >
                          £5
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => appendChar("4")}
                        >
                          4
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => appendChar("5")}
                        >
                          5
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => appendChar("6")}
                        >
                          6
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => setPayValue("10.00")}
                        >
                          £10
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => appendChar("1")}
                        >
                          1
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => appendChar("2")}
                        >
                          2
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => appendChar("3")}
                        >
                          3
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => setPayValue("20.00")}
                        >
                          £20
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => appendChar("0")}
                        >
                          0
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => appendChar(".")}
                        >
                          .
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => deleteLastChar()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="currentColor"
                            className="bi bi-backspace"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z" />
                            <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z" />
                          </svg>
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          style={buttonStyle}
                          onClick={() => setPayValue(props.toPayAmount)}
                        >
                          £{props.toPayAmount}
                        </Button>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </>
    </>
  );

  const customerModal = (): JSX.Element => {
    return (
      <>
        <Modal show={props.show} onHide={() => props.close()} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Total £{toPayAmount}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{saving ? savingData() : paymentScreen()}</Modal.Body>
          <Modal.Footer className="modal-footer">
            <div>
              <Button
                variant="info"
                style={{
                  width: 100,
                  height: 50,
                  fontSize: "16px",
                  fontWeight: "bolder",
                }}
                onClick={() => setPayValue("")}
              >
                Clear
              </Button>
            </div>
            <Button
              variant="danger"
              style={{
                width: 100,
                height: 50,
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              onClick={() => props.close()}
            >
              Cancel
            </Button>
            <Button
              variant="warning"
              style={{
                width: 100,
                height: 50,
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              disabled={true}
              onClick={() => completeOrder()}
            >
              Card
            </Button>
            <Button
              variant="info"
              style={{
                width: 100,
                height: 50,
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              disabled={true}
              onClick={() => completeOrder()}
            >
              Online
            </Button>

            <Button
              variant="success"
              style={{
                width: 150,
                height: 50,
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              onClick={() => completeOrder()}
            >
              Complete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  function getTotal() {
    let total = 0;
    context.Receipt.getRows().forEach((r) => {
      total += r.qty * r.price;
    });
    return total;
  }

  function completeOrder() {
    const enteredValue = payValue;
    const value = parseFloat(enteredValue);
    const total = parseFloat(toPayAmount);

    if (isNaN(value)) {
      return;
    }

    if (value < total) {
      return;
    }

    const rows = context.Receipt.getRows();
    const settings = context.Configuration.getSettings();
    let printerSettings = settings.printers[0];

    const printerJson = window.localStorage.getItem("printer");
    const noOfPrintsJson = window.localStorage.getItem("noOfPrinters");

    if (printerJson !== null) {
      printerSettings = JSON.parse(printerJson);
    }
    if (printerJson !== null) {
      printerSettings = JSON.parse(printerJson);
    }

    if (noOfPrintsJson !== null) {
      printerSettings.noOfPrints = Number(JSON.parse(noOfPrintsJson));
    }

    const organisation = settings.organisation;

    const customer = context.Customer.getCustomer();
    const user = context.AuthenticatedUser.getUser().fullname;
    const transactionType = context.Receipt.getTransactionType();
    const paymentType = context.Receipt.getPaymentType();
    const receiptNumber = context.Receipt.getReceiptNumber();

    let receipt: IReceipt = {
      id: 0,
      createdAt: new Date(),
      rows: rows,
      total: getTotal(),
      customerReceiptNumber: receiptNumber,
      organisation: organisation,
      customer: customer,
      transactionType: transactionType,
      paymentType: paymentType,
      user: user,
      endComment: organisation.endComment,
      enteredValue: value.toFixed(2),
      changeGiven: ((total - value) * -1).toFixed(2),
      printerSettings: printerSettings,
      table: "epos",
    };

    const receiptData: IReceiptData = {
      receipt: receipt,
      token: context.AuthenticatedUser.getToken(),
    };

    const receiptJson = JSON.stringify(receiptData);
    var encodedStringBtoA = btoa(receiptJson);

    let url = "https://print.aleaf.co.uk/Default.aspx?data=";
    url = url + encodedStringBtoA;

    const isSaved = saveReceipt(receiptData);

    Promise.all([isSaved]).then((responses) => {
      console.log(responses);
      if (responses) {
        let defaultUrl = printerSettings.defaultUrl + responses;

        window.location.href = url;
        //window.location.href = defaultUrl;

        props.showTransactionTypes();
        setPayValue("");
        return;
      }
    });
  }

  async function saveReceipt(data: IReceiptData) {
    setError(false);
    setSaving(true);

    var created = await createOrder(data, context.AuthenticatedUser.getToken());

    if (!created) {
      setError(true);
      return -1;
    }

    setSaving(false);
    return created;
  }

  return (
    <>
      <>{customerModal()}</>
    </>
  );
};
