/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
//import { authenticate } from "../../services/Authenticate";
import ApplicationContext from "../../../context/ApplicationContext";

import "./styles/bootstrap.css";

import { Button, Modal, Row } from "react-bootstrap";
import { ILoginProps } from "./ILoginProps";
import { authenticate } from "../../../services/Authenticate";
import { IAuth } from "../../../types/IAuth";
import loadingImage from "./loading_1.gif";
import Keyboard from "react-simple-keyboard";
import { useNavigate } from "react-router";

export const Login: React.FC<ILoginProps> = (
  props: ILoginProps
): JSX.Element => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(
    "mobile number/Password incorrect"
  );

  const [showSupportModal, setShowSupportModal] = React.useState(false);
  const [loginInProgress, setLoginInProgress] = React.useState(false);
  const [showKeyboard, setShowKeyboard] = React.useState(false);

  const [selectedUsername, setSelectedUsername] = React.useState(true);
  const [selectedPassword, setSelectedPassword] = React.useState(false);

  const context = useContext(ApplicationContext);
  const navigate = useNavigate();

  useEffect(() => {
    const user = window.localStorage.getItem("username");
    const pass = window.localStorage.getItem("password");

    setUsername(user !== null ? user : "");
    setPassword(pass != null ? pass : "");
  }, []);

  async function handleLogin(e: any) {
    setLoginInProgress(true);
    e.preventDefault();

    if (username.length === 0 || password.length === 0) {
      setErrorMsg("Login failed");
      setShowError(true);
      setLoginInProgress(false);
      return;
    }

    var auth = await authenticate(username, password);

    if (!auth) {
      setErrorMsg("Login failed");
      setShowError(true);
      return;
    }

    if (auth.status !== 200) {
      setErrorMsg("Login failed");
      setShowError(true);
      setLoginInProgress(false);
      return;
    }

    const data: IAuth = auth.data;
    context.AuthenticatedUser.setUser(data.user);
    context.AuthenticatedUser.setToken(data.tokenValue);

    setLoginInProgress(false);

    navigate("/Menu/true");

    setShowError(false);
  }

  function handleForgotPassword() {
    setShowSupportModal(true);
  }

  function onEnterHit(e: any) {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  }

  const supportModal = (): JSX.Element => {
    return (
      <>
        <Modal
          show={showSupportModal}
          onHide={() => setShowSupportModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Support</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br />
            <div
              style={{
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <h2>Please contact support on</h2>
              <h1>07929-929070</h1>
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{
                width: 150,
                height: 50,
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              onClick={() => setShowSupportModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  function toggleKeyboard() {
    //do something here
    let value = showKeyboard;

    if (value === true) {
      setShowKeyboard(false);
    } else {
      setShowKeyboard(true);
    }
  }

  function clearSelected() {
    setSelectedUsername(false);
    setSelectedPassword(false);
  }

  const loginForm = (): JSX.Element => {
    return (
      <>
        <div
          style={{
            backgroundColor: "white",
            width: "350px",
            borderRadius: "15px",
            textAlign: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontWeight: "bolder",
              fontSize: "40px",
              fontFamily: "sans-serif",
              paddingTop: 10,
            }}
          >
            EPOS
          </div>
          <div
            style={{
              fontWeight: "bolder",
              fontSize: "14px",
            }}
          >
            <a href="https://WaEPOS.com" target="_blank">
              WaEPOS.com
            </a>
          </div>
          <div
            style={{
              padding: 30,
            }}
          >
            <div className="form-group">
              {showError ? (
                <div className="alert alert-danger" role="alert">
                  {errorMsg}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="username"
                onFocus={() => {
                  clearSelected();
                  setSelectedUsername(true);
                }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => {
                  clearSelected();
                  setSelectedPassword(true);
                }}
              />
              <Row style={{ paddingTop: 10 }}>
                <Button
                  variant="link"
                  className="tx-info tx-12 d-block mg-t-10"
                  onClick={handleForgotPassword}
                >
                  Forgot password?
                </Button>
                <Button
                  variant="link"
                  className="tx-info tx-12 d-block mg-t-10"
                  onClick={() => toggleKeyboard()}
                  style={{ paddingLeft: 100 }}
                >
                  Keyboard
                </Button>
              </Row>
            </div>
            <Button
              variant="primary"
              className="btn btn-primary btn-block"
              onClick={handleLogin}
              disabled={loginInProgress}
              // style={{ height: 20, width: 200 }}
            >
              {loginInProgress ? (
                <img width={20} height={20} src={loadingImage} alt="Loading" />
              ) : (
                <>Login</>
              )}
            </Button>
          </div>
        </div>
      </>
    );
  };

  function onKeyboardKeyPress(value: any) {
    let newValue = "";

    if (selectedUsername) {
      newValue = username;
    }

    if (selectedPassword) {
      newValue = password;
    }

    if (value === "{bksp}") {
      newValue = newValue.substring(0, newValue.length - 1);
    }

    if (value === "{space}") {
      newValue = newValue + " ";
    }

    if (value === "{tab}") {
      newValue = newValue + "";
    }

    if (value !== "{bksp}" && value !== "{space}" && value !== "{tab}") {
      newValue = newValue + value;
    }

    if (selectedUsername) {
      setUsername(newValue);
    }

    if (selectedPassword) {
      setPassword(newValue);
    }
  }

  const KeyboardView = (): JSX.Element => {
    return (
      <>
        sfd
        {showKeyboard ? (
          <div
            style={{
              width: "1024px",
            }}
          >
            <Keyboard
              physicalKeyboardHighlightPress={true}
              physicalKeyboardHighlight={true}
              onKeyPress={onKeyboardKeyPress}
            />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const login = (): JSX.Element => {
    return (
      <form onKeyUp={onEnterHit}>
        <div
          style={{
            backgroundColor: "#1D2939",
            width: "100vw",
            height: "100vh",
            textAlign: "center",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <table>
            <tr>
              <td
                style={{
                  backgroundColor: "#1D2939",
                  textAlign: "center",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {loginForm()}
              </td>
            </tr>
            <tr>
              <td style={{ alignItems: "center" }}>{KeyboardView()}</td>
            </tr>
          </table>
        </div>
      </form>
    );
  };

  return (
    <>
      {supportModal()}
      <div>{login()}</div>
    </>
  );
};
