import axios, { AxiosResponse } from "axios";
import { ISettings } from "../types/ISettings";
import { IReceiptData } from "../types/IReceiptData";
import { ITelephone } from "../types/ITelephone";
import { apiUrl } from "../EposConfig";

const baseConfig = {
  baseURL: apiUrl,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer ",
  },
  validateStatus: () => true,
};

export const getSettings = (token: string): Promise<ISettings | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .get("Settings", config)
    .then((response: AxiosResponse<ISettings>) => {
      return response.data;
    })
    .catch(handleError);
};

export const getTelephoneData = (
  token: string
): Promise<ITelephone[] | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .get("Telephone", config)
    .then((response: AxiosResponse<ITelephone[]>) => {
      return response.data;
    })
    .catch(handleError);
};

export const createOrder = (
  data: IReceiptData,
  token: string
): Promise<number | void> => {
  const config = baseConfig;
  config.headers.Authorization = "Bearer " + token;
  return axios
    .post("Receipt/Add", data, config)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

function handleError(error: any) {
  console.log("api error @ " + new Date());
  console.log(error);
  console.log(error.response);

  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }
}
