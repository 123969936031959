import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ISiteProps } from "./ISiteProps";

export const SiteModal: React.FC<ISiteProps> = (
  props: ISiteProps
): JSX.Element => {
  const [appId, setAppId] = React.useState("");

  function setOrganisationUrl() {
    let url = "https://store.WaEPOS.com?data=";
    url = url + btoa(appId);

    window.location.href = url;
    props.close();
  }

  const siteModal = (): JSX.Element => {
    return (
      <>
        <Modal show={props.show} onHide={() => props.close()} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Organisation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">URL</label>
              <div style={{ paddingLeft: 10 }}>
                <Form.Control
                  aria-describedby="basic-addon1"
                  style={{ fontSize: "16px", width: "600px" }}
                  onChange={(e) => setAppId(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button
              variant="secondary"
              style={{
                width: 150,
                height: 50,
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              onClick={() => setAppId("")}
            >
              Clear
            </Button>
            <Button
              variant="success"
              style={{
                width: 150,
                height: 50,
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              onClick={() => setOrganisationUrl()}
            >
              Save & Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  return (
    <>
      <>{siteModal()}</>
    </>
  );
};
